import axios from "axios";

import { VendorEntity } from "../components/pages/Dashboard/Organisations/Entities/types";
import { EntityOnboardingStatus, EntityStatus } from "../types/entity";
import { IntegrationProviderId, IntegrationType } from "../types/integration";

import { assertUnreachable } from "./utils";

export enum OrganisationOnboardingProgressState {
  NotStarted = "not_started",
  NoEntities = "no-entities",
  NoPriority = "no-priority-selected",
  Done = "done",
}

export type OrganisationOnboardingProgress =
  | {
      state: OrganisationOnboardingProgressState.NotStarted | OrganisationOnboardingProgressState.Done;
    }
  | {
      state: OrganisationOnboardingProgressState.NoEntities;
      providerId: IntegrationProviderId;
      integrationId: string;
    }
  | {
      state: OrganisationOnboardingProgressState.NoPriority;
      providerId: IntegrationProviderId;
      entities: VendorEntity[];
    };

interface Integration {
  id: string;
  provider: IntegrationProviderId;
  type: IntegrationType.Bookkeeping;
  entities: {
    id: string;
    name: string;
    status: EntityStatus;
    onboardingStatus: EntityOnboardingStatus;
    vatNumber: string | null;
  }[];
}

export async function getOrganisationOnboardingProgress(orgId: string): Promise<OrganisationOnboardingProgress> {
  const { data: integrations } = await axios.get<Integration[]>(`/organisations/${orgId}/integrations`, {
    params: { type: "bookkeeping" },
  });

  if (integrations.length === 0) return { state: OrganisationOnboardingProgressState.NotStarted };

  const noCsvIntegrations = integrations.filter(i => i.provider !== "csv");

  const integrationWithNoEntities = noCsvIntegrations.find(i => i.entities.length === 0);
  if (integrationWithNoEntities !== undefined)
    return {
      state: OrganisationOnboardingProgressState.NoEntities,
      providerId: integrationWithNoEntities.provider,
      integrationId: integrationWithNoEntities.id,
    };

  const integrationWithNoPriority = noCsvIntegrations.find(i =>
    i.entities.every(e => e.onboardingStatus === "not_started"),
  );
  if (integrationWithNoPriority !== undefined)
    return {
      state: OrganisationOnboardingProgressState.NoPriority,
      providerId: integrationWithNoPriority.provider,
      entities: integrationWithNoPriority.entities.map(e => ({
        id: e.id,
        name: e.name,
        vatNumber: e.vatNumber,
        active: true,
      })),
    };

  return { state: OrganisationOnboardingProgressState.Done };
}

export function organisationOnboardingProgressToStepIndex(
  onboardingProgress: OrganisationOnboardingProgressState,
): number | undefined {
  switch (onboardingProgress) {
    case OrganisationOnboardingProgressState.NotStarted:
      return 0;
    case OrganisationOnboardingProgressState.NoEntities:
      return 1;
    case OrganisationOnboardingProgressState.NoPriority:
      return 2;
    case OrganisationOnboardingProgressState.Done:
      return;
    default:
      assertUnreachable(onboardingProgress);
  }
}

export function entityOnboardingProgressToStepIndex(onboardingProgress: EntityOnboardingStatus): number | undefined {
  switch (onboardingProgress) {
    case EntityOnboardingStatus.NotStarted:
    case EntityOnboardingStatus.Dates:
      return 0;
    case EntityOnboardingStatus.Reporting:
      return 1;
    case EntityOnboardingStatus.Mapping:
      return 3;
    case EntityOnboardingStatus.Dashboard:
      return 5;
    case EntityOnboardingStatus.Done:
      return;
    default:
      assertUnreachable(onboardingProgress);
  }
}
