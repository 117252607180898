import { Spinner, SpinnerSize, initializeIcons } from "@fluentui/react";
import { FlagsProvider } from "flagged";
import { FC, Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import ConsolidationCheck from "./components/molecules/ConsolidationCheck";
import Protected from "./components/molecules/Protected";
import Billing from "./components/pages/Dashboard/Organisations/Billing";
import { ExportProvider } from "./components/pages/Dashboard/Organisations/Entities/Export/ExportContext";
import { useAuthentication } from "./context/AuthenticationContext";
import { JournyProvider } from "./context/JournyContext";
import UserGuidingProvider from "./context/UserGuidingContext";
import { UserSettingsProvider } from "./context/UserSettingsContext";
import { DashboardIndexRoute } from "./routes/DashboardIndexRoute";
import EntityRoute from "./routes/EntityRoute";
import { OrganisationRoute } from "./routes/OrganisationRoute";
import PrivateRoute from "./routes/PrivateRoute";
import ScreenSizeRoute from "./routes/ScreenSizeRoute";
import { SubscriptionTier } from "./types/organisation";

const AuthLayout = lazy(
  () => import("./components/layouts/Auth" /* webpackPrefetch: true, webpackChunkName: "layout-auth" */),
);
const Onboarding = lazy(() => import("./components/layouts/Onboarding" /* webpackChunkName: "layout-onboarding" */));
const OnboardingFlowOrganisation = lazy(
  () => import("./components/pages/onboarding/Organisation" /* webpackChunkName: "onboarding-flow-organisation" */),
);
const OnboardingFlowEntity = lazy(
  () => import("./components/pages/onboarding/Entity" /* webpackChunkName: "onboarding-flow-entity" */),
);
const Dashboard = lazy(
  () => import("./components/layouts/Dashboard" /* webpackPrefetch: true, webpackChunkName: "layout-dashboard" */),
);
const Profile = lazy(() => import("./components/pages/Dashboard/User/Profile" /* webpackChunkName: "user-profile" */));
const Invites = lazy(() => import("./components/pages/Dashboard/User/Invites" /* webpackChunkName: "user-invites" */));
const Login = lazy(() => import("./components/pages/Login" /* webpackChunkName: "page-login" */));
const MFA = lazy(() => import("./components/pages/Login/MFA" /* webpackChunkName: "page-login-MFA" */));
const Register = lazy(() => import("./components/pages/Register" /* webpackChunkName: "page-register" */));
const SetPassword = lazy(
  () => import("./components/pages/Register/Password" /* webpackChunkName: "page-set-password" */),
);
const SetupMFA = lazy(() => import("./components/pages/Register/MFA" /* webpackChunkName: "page-setup-MFA" */));
const ForgotPassword = lazy(
  () => import("./components/pages/Password/Forgot" /* webpackChunkName: "page-forgot-password" */),
);
const ResetPassword = lazy(
  () => import("./components/pages/Password/Reset" /* webpackChunkName: "page-reset-password" */),
);
const EntityDashboard = lazy(
  () =>
    import(
      "./components/pages/Dashboard/DashboardStrategy/EntityDashboard" /* webpackPrefetch: true, webpackChunkName: "entity-dashboard" */
    ),
);
const OrganisationDashboard = lazy(
  () =>
    import(
      "./components/pages/Dashboard/DashboardStrategy/OrganisationDashboard" /* webpackPrefetch: true, webpackChunkName: "organisation-dashboard" */
    ),
);
const Reports = lazy(
  () =>
    import("./components/pages/Dashboard/Reports" /* webpackPrefetch: true, webpackChunkName: "dashboard-reports" */),
);
const Entities = lazy(
  () => import("./components/pages/Dashboard/Organisations/Entities" /* webpackChunkName: "entities" */),
);
const PdfExport = lazy(
  () => import("./components/pages/Dashboard/Organisations/Entities/Export" /* webpackChunkName: "entities" */),
);
const Teams = lazy(() => import("./components/pages/Dashboard/Organisations/Teams" /* webpackChunkName: "teams" */));
const Integrations = lazy(
  () => import("./components/pages/Dashboard/Organisations/Integrations" /* webpackChunkName: "integrations" */),
);
const OrganisationSettings = lazy(
  () => import("./components/pages/Dashboard/Organisations/Settings" /* webpackChunkName: "organisation-settings" */),
);
const DefinitionMapping = lazy(
  () =>
    import(
      "./components/pages/Dashboard/Definitions/DefinitionReporting/Mapping" /* webpackChunkName: "definitions-mapping" */
    ),
);
const DefinitionEntityReporting = lazy(
  () =>
    import(
      "./components/pages/Dashboard/Definitions/DefinitionReporting/Reporting/EntityReport" /* webpackChunkName: "definitions-reporting" */
    ),
);

const DefinitionOrganisationReporting = lazy(
  () =>
    import(
      "./components/pages/Dashboard/Definitions/DefinitionReporting/Reporting/OrganisationReport" /* webpackChunkName: "definitions-reporting" */
    ),
);

const DefinitionBudget = lazy(
  () =>
    import("./components/pages/Dashboard/Definitions/DefinitionBudget" /* webpackChunkName: "definitions-budget" */),
);
const AdjustingEntries = lazy(
  () =>
    import(
      "./components/pages/Dashboard/Definitions/AdjustingEntries/Entity" /* webpackChunkName: "definitions-adjusting-entries" */
    ),
);
const ConsolidationAdjustingEntries = lazy(
  () =>
    import(
      "./components/pages/Dashboard/Definitions/AdjustingEntries/Consolidation" /* webpackChunkName: "consolidation-adjusting-entries" */
    ),
);
const Validations = lazy(
  () =>
    import("./components/pages/Dashboard/Definitions/Validations" /* webpackChunkName: "definitions-validations" */),
);
const ConsolidationValidations = lazy(
  () =>
    import(
      "./components/pages/Dashboard/Organisations/ConsolidationValidations" /* webpackChunkName: "consolidation-validations" */
    ),
);

initializeIcons();

const App: FC = () => {
  const { decodedToken } = useAuthentication();
  const isMonitrEmailAddress = decodedToken ? /@monitr.be\s*$/.test(decodedToken?.email) : false;

  return (
    <FlagsProvider
      features={{
        // Monitr email addresses have access to experimental features
        experimental: isMonitrEmailAddress,
        // Monitr email addresses have access to deprecated features
        deprecated: isMonitrEmailAddress,
        // Monitr email addresses don't log data to journy
        enableJourny: !isMonitrEmailAddress,
      }}
    >
      <UserSettingsProvider>
        <Suspense fallback={<Spinner style={{ height: "100%" }} size={SpinnerSize.large} />}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route
              path="onboarding"
              element={
                <PrivateRoute>
                  <ScreenSizeRoute>
                    <Onboarding type={"organisation"}>
                      <JournyProvider>
                        <UserGuidingProvider>
                          <OnboardingFlowOrganisation />
                        </UserGuidingProvider>
                      </JournyProvider>
                    </Onboarding>
                  </ScreenSizeRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="onboarding/entity"
              element={
                <PrivateRoute>
                  <ScreenSizeRoute>
                    <Onboarding type={"entity"}>
                      <JournyProvider>
                        <UserGuidingProvider>
                          <OnboardingFlowEntity />
                        </UserGuidingProvider>
                      </JournyProvider>
                    </Onboarding>
                  </ScreenSizeRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <ScreenSizeRoute>
                    <Dashboard>
                      <JournyProvider>
                        <UserGuidingProvider>
                          <Outlet />
                        </UserGuidingProvider>
                      </JournyProvider>
                    </Dashboard>
                  </ScreenSizeRoute>
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <OrganisationRoute>
                    <EntityRoute>
                      <DashboardIndexRoute />
                    </EntityRoute>
                  </OrganisationRoute>
                }
              />
              <Route
                path=":dashboard"
                element={
                  <EntityRoute>
                    <Suspense fallback={null}>
                      <EntityDashboard />
                    </Suspense>
                  </EntityRoute>
                }
              />
              <Route
                path=":dashboard/:dashboardType"
                element={
                  <EntityRoute>
                    <Suspense fallback={null}>
                      <EntityDashboard />
                    </Suspense>
                  </EntityRoute>
                }
              />
              <Route
                path="reports"
                element={
                  <EntityRoute>
                    <Protected requiredTier={SubscriptionTier.Lite} permissions={["entity.reports:read"]}>
                      <Outlet />
                    </Protected>
                  </EntityRoute>
                }
              >
                <Route index element={<Reports />} />
                <Route path=":report" element={<Reports />} />
                <Route path=":report/:type" element={<Reports />} />
              </Route>
              <Route path="profile" element={<Outlet />}>
                <Route index element={<Profile />} />
                <Route path=":tab" element={<Profile />} />
              </Route>
              <Route path="invites" element={<Invites />} />
              <Route path="organisations">
                <Route
                  path="settings"
                  element={
                    <Protected
                      requiredTier={SubscriptionTier.Lite}
                      permissions={["organisation.organisation_data:read"]}
                    >
                      <OrganisationSettings />
                    </Protected>
                  }
                />
                <Route
                  path="billing"
                  element={
                    <Protected
                      requiredTier={SubscriptionTier.Lite}
                      permissions={["organisation.organisation_data:read"]}
                    >
                      <Billing />
                    </Protected>
                  }
                />
                <Route
                  path="entities"
                  element={
                    <EntityRoute>
                      <Outlet />
                    </EntityRoute>
                  }
                >
                  <Route index element={<Entities />} />
                </Route>
                <Route
                  path="entities"
                  element={
                    <Protected requiredTier={SubscriptionTier.Lite} permissions={["entity.core:read"]}>
                      <EntityRoute>
                        <Outlet />
                      </EntityRoute>
                    </Protected>
                  }
                >
                  <Route
                    path="export"
                    element={
                      <ExportProvider consolidation={false}>
                        <PdfExport />
                      </ExportProvider>
                    }
                  />

                  <Route path="definitions/mapping" element={<DefinitionMapping />} />
                  <Route path="definitions/reporting/:subtab" element={<DefinitionEntityReporting />} />
                  <Route
                    path="definitions/budget"
                    element={<Navigate to="/dashboard/organisations/entities/definitions/budget/forecasts" replace />}
                  />
                  <Route
                    path="definitions/budget/:tab"
                    element={
                      <Protected requiredTier={SubscriptionTier.Premium} permissions={["entity.core:read"]}>
                        <DefinitionBudget />
                      </Protected>
                    }
                  />
                  <Route path="definitions/adjusting-entries" element={<AdjustingEntries />} />
                  <Route path="definitions/validations" element={<Validations />} />
                </Route>
                <Route
                  path="consolidation"
                  element={
                    <Protected requiredTier={SubscriptionTier.Premium} permissions={["organisation.reports:read"]}>
                      <ConsolidationCheck consolidation={true}>
                        <Outlet />
                      </ConsolidationCheck>
                    </Protected>
                  }
                >
                  <Route
                    path="metrics"
                    element={
                      <EntityRoute>
                        <Suspense fallback={null}>
                          <OrganisationDashboard />
                        </Suspense>
                      </EntityRoute>
                    }
                  />
                  <Route path="reports" element={<Outlet />}>
                    <Route index element={<Reports consolidation={true} />} />
                    <Route path=":report" element={<Reports consolidation={true} />} />
                    <Route path=":report/:type" element={<Reports consolidation={true} />} />
                  </Route>
                  <Route
                    path="definitions"
                    element={
                      <Protected
                        requiredTier={SubscriptionTier.Premium}
                        permissions={["organisation.organisation_data:read"]}
                      >
                        <Outlet />
                      </Protected>
                    }
                  >
                    <Route path="mapping" element={<DefinitionMapping />} />
                    <Route path="reporting/:subtab" element={<DefinitionOrganisationReporting />} />
                    <Route path="adjusting-entries" element={<ConsolidationAdjustingEntries />} />
                    <Route path="validations" element={<ConsolidationValidations />} />
                  </Route>
                  <Route
                    path="export"
                    element={
                      <ExportProvider consolidation={true}>
                        <PdfExport />
                      </ExportProvider>
                    }
                  />
                </Route>
              </Route>
              <Route path="manage">
                <Route index element={<Navigate to="./teams" replace />} />
                <Route
                  path="teams"
                  element={
                    <Protected requiredTier={SubscriptionTier.Lite} permissions={["organisation.teams:read"]}>
                      <Outlet />
                    </Protected>
                  }
                >
                  <Route index element={<Teams />} />
                  <Route path=":tab" element={<Teams />} />
                </Route>
                <Route
                  path="integrations"
                  element={
                    <Protected
                      requiredTier={SubscriptionTier.Lite}
                      permissions={["organisation.organisation_data:read"]}
                    >
                      <Integrations />
                    </Protected>
                  }
                >
                  <Route path=":integrationType" element={<Integrations />} />
                </Route>
              </Route>
            </Route>
            <Route
              path="login"
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route index element={<Login />} />
              <Route path="mfa" element={<MFA />} />
            </Route>
            <Route
              path="register"
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route index element={<Register />} />
              <Route path="password" element={<SetPassword />} />
              <Route path="mfa" element={<SetupMFA />} />
            </Route>
            <Route
              path="password"
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route path="forgot" element={<ForgotPassword />} />
              <Route path="reset" element={<ResetPassword />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </UserSettingsProvider>
    </FlagsProvider>
  );
};

export default App;
